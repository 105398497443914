import { readState } from "./history";

/**
 * current uri that is scrolling:
 */
let _currentUriScrolling: string | undefined = undefined;
let _currentUriScrollPos: number | undefined = undefined;
let _clearCurrentUriTimeout: any = undefined;

function buildScrollStateName(uri: string) {
    const state = readState() || {key: 'initial'};
    return ['@@scroll', uri, state.key].join("|");
}


/**
 * store current scroll position by uri
 */
export function storeScrollPosition(uri: string) {
    const stateName = buildScrollStateName(uri);
    // wait for browser scroll, then scroll to real position:
    if (_currentUriScrolling === stateName) {
        if (_clearCurrentUriTimeout) clearTimeout(_clearCurrentUriTimeout);
        _clearCurrentUriTimeout = setTimeout(() => {
            _currentUriScrolling = undefined;
            if (_currentUriScrollPos) window.scrollTo(0, _currentUriScrollPos);
            _currentUriScrollPos = undefined;
        }, 130);
        return false;
    }
    // store scroll position for current uri:
    const scolls = getScroll();
    const scrollPosition = scolls[1];
    sessionStorage.setItem(stateName, JSON.stringify(scrollPosition));
}

/**
 * restore current scroll position by uri
 */
export function restoreScrollPosition(uri: string) {
    try {
        const url = new URL(uri);
        if (url.hash) { return false; }
    } catch (err) {}

    const stateName = buildScrollStateName(uri);
    if (sessionStorage[stateName]) {
        const scrollTop = parseInt( sessionStorage.getItem(stateName) || "0" );
        _currentUriScrolling = stateName;
        _currentUriScrollPos = scrollTop;
        window.scrollTo(0, scrollTop);
        return true;
    }
    return false;
}

/**
 * Retrieves the current scroll position.
 * @returns {number[]} scroll positions
 */
export function getScroll() {
    if (window.scrollY != undefined) {
        return [window.scrollX, window.scrollY];
    } else {
        let sx, sy, d = document,
            r = d.documentElement,
            b = d.body;
        sx = r.scrollLeft || b.scrollLeft || 0;
        sy = r.scrollTop || b.scrollTop || 0;
        return [sx, sy];
    }
}