import React from "react";
import { navigate } from "gatsby";

import Link from "src/components/link";
import Cart from "src/components/cart";
import ImageV2 from "src/components/image/image.v2";
import Icon, { MaterialIcon } from "src/components/icon";
import CurrencyPicker from "src/layouts/components/currencypicker";
import LanguagePicker from "src/layouts/components/languagepicker";

import { getSession } from "src/utils/localStorage";
import { getLanguageField } from "src/utils/language";
import { getSlug } from "src/utils/slugs";

type ToolbarProps = {
    scrolledDown: boolean;
    toolbar: TToolbar;
    
    language: string;
    defaultLanguage: string;
    languages: Array<{code: string; name: string; icon: null | string}>;
    page_translations: TTranslations<{slug: string;}>;

    defaultCurrency: null | string;
    currencies: Array<{id: number; code: string; name: string;}>;

    pages: {
        account_page: null | TTranslations<{slug: string}>;
        shopping_cart_page: null | TTranslations<{slug: string}>;
    }

    onLoginButtonClick: (open: boolean) => void;

    closeMenuFn: () => void;
}

export default class ToolbarCommon extends React.Component<ToolbarProps> {

    public get shadow() {
        if (this.props.toolbar.desktop_shadow) return 'shadow';
        return '';
    }

    renderMyAccount() {
        if (!this.props.toolbar.user_profile) return null;
        const session = getSession();
        const isUserLogged = session && session.customer;
        const accountpage = this.props.pages?.account_page?.find(translation => translation.language === this.props.language);
        if (isUserLogged) return <Link title="" href={accountpage?.slug} className="self-center"><Icon icon="UserRounded" className="w-6 h-6" /></Link>;
        
        return <button onClick={() => this.props.onLoginButtonClick(true)} className="text-[color:var(--text-color)] hover:text-[color:var(--hover-color)]">
            <Icon icon="UserRounded" className="w-6 h-6" />
        </button>;
    }

    renderCart() {
        if (!this.props.toolbar.shopping_cart) return null;
        const cart_page = this.props.pages?.shopping_cart_page?.find(translation => translation.language === this.props.language);
        return <Link title="" href={cart_page?.slug} className="text-[color:var(--text-color)] hover:text-[color:var(--hover-color)]">
            <Cart />
        </Link>
    }

    renderCurrencyPicker(modalInTop: boolean = false) {
        if (!this.props.toolbar.with_currency_selector) return null;
        if (!this.props.defaultCurrency) return null;
        return <CurrencyPicker 
            currencies={this.props.currencies} 
            defaultCurrency={this.props.defaultCurrency}
            labelClassName="caption"
            itemsClassName="caption"
            modalInTop={modalInTop}
        />
    }

    renderLanguagePicker() {
        const toolbar = this.props.toolbar;
        if (!toolbar.with_language_selector) return null;
        return <LanguagePicker 
            labelClassName="caption"
            itemsClassName="caption"
            language={this.props.language}
            languages={this.props.languages}
            page_translations={this.props.page_translations}
            language_selector_on_navigation_bar={toolbar.language_selector_on_toolbar}
            language_selector_on_dropdown={toolbar.language_selector_on_dropdown}
            language_selector_icon={toolbar.language_selector_icon}
            language_flag_style={toolbar.language_flag_style}
            language_selector_dropdown_chevron_icon={toolbar.language_selector_dropdown_chevron_icon}
        />;
    }

    renderToolbarItem(item: TToolbarItem, index: number) {
        let icon = item.item.icon_type === 'personalized'
            ? <ImageV2 className="h-4 w-4 mr-2" src={item.item.personalized_icon?.imageFile} />
            : <MaterialIcon className="h-4 w-4 mr-2" icon={item.item.material_icon || ""} />;

        const label = getLanguageField(item.item.translations, this.props.language, 'label');
        const link = item.item.link_type === 'external'
            ? item.item.link_href as string
            : getSlug(this.props.language, this.props.defaultLanguage, item.item.link_page?.translations || []);
        
        const closeMenuFn = this.props.closeMenuFn;
        
        return (
        <div key={`toolbaritem-${index}`} className="caption font-bold toolbar-item flex flex-row text-[color:var(--text-color)] hover:text-[color:var(--hover-color)]" onClick={() => { closeMenuFn(); navigate(link); }}>
            { item.item.icon_type !== "none" ? icon : null }
            { label && label !== "" ? <span>{label}</span> : null}
        </div>
        );
    }

}