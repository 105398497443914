import React, { startTransition } from "react";

import Accordion from "src/components/accordion";
import CurrencyIcon from "src/components/currencyIcon";
import Icon from "src/components/icon";
import { ApplicationContext } from "src/context";

type CurrencyPickerProps = {
    defaultCurrency: string;
    currencies: Array<{id: number; code: string; name: null | string;}>;
    labelClassName: string;
    itemsClassName: string;
    modalInTop?: boolean;
    onChange?: (currency: string) => void;
    onToggleDropdown?: (open: boolean) => void;
}

type CurrencyPickerState = {
    mounted: boolean;
}

export default class CurrencyPicker extends React.Component<CurrencyPickerProps> {
    static contextType = ApplicationContext;
    /** @ts-ignore */
    context!: React.ContextType<typeof ApplicationContext>;

    public state: CurrencyPickerState = { mounted: false };

    private accordionRef = React.createRef<Accordion>();

    private findNameForCurrencyCode(code: string | undefined) {
        const currencyItem = this.props.currencies.find((item) => item.code === code );
        if (currencyItem) return currencyItem.name;
        return "-";
    }

    public pickCurrency(currencyCode: string) {
        if (this.props.onChange) this.props.onChange(currencyCode);
        this.context.setCurrency(currencyCode);
        this.accordionRef.current?.close();
    }

    public get isOpen() {
        return this.accordionRef.current?.state.open || false;
    }

    public open() {
        if (this.accordionRef.current) this.accordionRef.current.open();
    }

    public close() {
        if (this.accordionRef.current) this.accordionRef.current.close();
    }

    onToggleDropdown(open: boolean) {
        if (this.props.onToggleDropdown) this.props.onToggleDropdown(open);
        this.forceUpdate();
    }

    componentDidMount(): void {
        startTransition(() => { this.setState({mounted: true}); });
    }

    renderLoader() {
        return <>
        <div className={`currency-opener cursor-pointer flex flex-row items-center text-[color:var(--text-color)] hover:text-[color:var(--hover-color)] ${this.props.labelClassName || ""}`}>
            <CurrencyIcon className="w-5 h-5" icon="Blank" />
            <span className="mx-2">CURR</span>
            <Icon className={`w-4 h-4`} icon="ChevronDown" />
        </div>
        </>;
    }

    public render() {
        const top = this.props.modalInTop === true ? 'bottom-6' : 'top-6';

        const chevronRotation = this.props.modalInTop
            ? (this.isOpen ? '' : 'rotate-180')
            : (this.isOpen ? 'rotate-180' : '');
        
        if (!this.state.mounted) return this.renderLoader();

        return <>
        <div className="relative">
            <Accordion
                ref={this.accordionRef}
                removeChildrenWhenClosed={true} 
                onToggle={(open) => this.onToggleDropdown(open)}
                closeOther={true}
                opener={<div className={`currency-opener cursor-pointer flex flex-row items-center text-[color:var(--text-color)] hover:text-[color:var(--hover-color)] ${this.props.labelClassName || ""}`}>
                    <CurrencyIcon className="w-5 h-5" icon={this.context.currency as any} />
                    <span className="mx-2">{this.findNameForCurrencyCode(this.context.currency)}</span>
                    <Icon className={`w-4 h-4 ${chevronRotation}`} icon="ChevronDown" />
                </div>}>
                <div className={`currency-modal ${top} absolute z-50 rounded-lg shadow-md py-6 flex flex-col gap-4 text-[color:var(--dropdown-text-color)] bg-[color:var(--dropdown-background)] border border-[color:var(--dropdown-outline)]`}>
                    {this.props.currencies.map((currency) => {
                        const {code, name} = currency;
                        const isSelected = code === this.context.currency;
                        const selectedClass = isSelected ? 'font-bold text-[color:var(--dropdown-active-color)]' : '';
                        return <div key={`currency-${code}`} onClick={(ev) => this.pickCurrency(code)} className={`flex flex-row items-center mx-6 cursor-pointer toolbar-currency-item hover:text-[color:var(--dropdown-hover-color)] ${this.props.itemsClassName || ""} ${isSelected ? 'selected': ''} ${selectedClass}`}>
                            <CurrencyIcon className="w-5 h-5" icon={code as any} />
                            <span className="ml-2">{name}</span>
                        </div>
                    })}
                </div>
            </Accordion>
        </div>
        </>
    }
}