import currency from 'currency.js';

// documentation
// https://github.com/scurker/currency.js

const formats = {
  nok: {
    symbol: 'NOK',
    separator: ' ',
    decimal: ',',
    precision: 0,//2 Bjorli
    pattern: '# !',//'# !' Bjorli
    negativePattern: '-# !'
  },
  eur: {
    symbol: '€',
    separator: '',
    decimal: ',',
    precision: 2,
    pattern: '# !',
    negativePattern: '-# !'
  },
  cad: {
    symbol: 'CAD',
    separator: ',',
    decimal: '.',
    precision: 2,
    pattern: '# !',
    negativePattern: '-# !'
  },
  default: {
    symbol: '',
    separator: ' ',
    decimal: ',',
    precision: 0,
    pattern: '# !',
    negativePattern: '-# !'
  }
};

export function toCurrency({value, valueCurrency, options = false, showAsFree = false}) {
  let opts = {};
  valueCurrency = valueCurrency?.toLowerCase();

  if(formats[valueCurrency]) {
    opts = formats[valueCurrency];
  } else {
    opts = formats.default
  }

  if(options) {
    opts = options;
  }

  if(isNaN(value)) {
    return null;
  }

  if (value === 0 && showAsFree) {
    return (
      "Free!"
    )
  }

  opts = checkDecimalsAndSeparator(opts, value);
  let r = currency(value, opts);
  if(r) {
    return r.format();
  }

  return null;
}

const checkDecimalsAndSeparator = (options, value) => {
  //checks if the number is a decimal and adds to the options the decimal separator used (. or ,)
  let opts = options;
  if(value % 1 !== 0){
    opts.precision = 2;
    if(typeof value === "string" && value.includes('.')){
      opts.decimal = '.';
    }
    else{
      opts.decimal = ','
    }
  }
  return opts;
}

export const currencyFromIp = async () => {
  try {
    const reponse = await fetch('https://kymguavwd7.execute-api.us-east-1.amazonaws.com/ip/')
    const data = await reponse.json()
    const country = await (await fetch(`https://connect.spotlio.com/system/getGeoInfo?ip=${data.ip}`)).json()
    const countryListEurope = ["ES", "FR", "IT", "PT"]

    if (countryListEurope.find(c => c === country.country)) return "EUR"
    if (country.country === "US") return "USD"
    if (country.country === "CA") return "CAD"

    return "";
  }
  catch(error){
    console.error("error getting ip", error);
  }
}