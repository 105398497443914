import { CONNECT, CONNECT_CLIENT } from "src/utils/definitions"
import request from "src/utils/request"

export async function subscribeToConnect(email: string) {
  const response: any = await request({
    method: "POST",
    url: `${CONNECT}/flamingo/newsletter`,
    data: {
      client: CONNECT_CLIENT,
      data: { email }
    }
  }) as any;
  return response.data;
}