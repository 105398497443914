import React from 'react';

import { convertMargin, convertPadding } from '../../utils/paddings';
import { slugify } from '../../utils/slugify';


type ModulePaddings = {
  top: 'none' | 'small' | 'medium' | 'large' | undefined;
  bottom: 'none' | 'small' | 'medium' | 'large' | undefined;
}

type ModuleWrapperProps = {
  name: string | undefined;
  anchor: string | undefined;
  paddings: ModulePaddings | undefined;
  className: string | undefined;
  backgroundColor: string | undefined;
  overlayColor?: string | undefined;
  backgroundImage?: string | undefined;
  roundedCorners: boolean;
  children: React.ReactNode | undefined;
  style?: React.CSSProperties & TCSSVars;
  isFullWidth?: boolean;
}

export class ModuleWrapperV2 extends React.Component<ModuleWrapperProps> {

  static defaultProps = {
    className: "",
    isFullWidth: true
  }
  //FLM-5955-> now directus paddings will work as margins when module is boxed.
  get padding_top() {
    const { isFullWidth } = this.props;
    if (isFullWidth) return convertPadding(this.props.paddings?.top || 'none', 'top');
    return convertMargin(this.props.paddings?.top || 'none', 'top');
  }
  //FLM-5955-> now directus paddings will work as margins when module is boxed.
  get padding_bottom() {
    const { isFullWidth } = this.props;
    if (isFullWidth) return convertPadding(this.props.paddings?.bottom || 'none', 'bottom');
    return convertMargin(this.props.paddings?.bottom || 'none', 'bottom');
  }

  get background_image() {
    if (!this.props.backgroundImage) return undefined;
    return `url(https://ik.imagekit.io/spotlio/fetch/tr:w-1920/https://public-assets.spotliodata.com/${this.props.backgroundImage})`
  }

  get rounded() {
    if(this.props.roundedCorners) return "rounded-2xl";
    return "";
  }

  get backgroundImageAndOverlayStyles(){
    let styles = "";
    if(this.background_image) styles += this.background_image
    else return this.props.backgroundColor || "";
    if(this.overlayColor) styles += `, linear-gradient(${this.overlayColor},${this.overlayColor})`
    return styles;
  }

  get overlayColor (){
    return this.props.overlayColor || "";
  }

  get overlayStyle(){
    if(this.overlayColor) return `, linear-gradient(${this.overlayColor},${this.overlayColor})`
  }

  get id() {
    if (this.props.anchor) return this.props.anchor;
    if (this.props.name) return slugify(this.props.name);
    return undefined;
  }

  get style() {
    const outterStyles = this.props.style || {};
    const stylevars: any = {
      ...outterStyles,
      background: this.backgroundImageAndOverlayStyles,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundBlendMode: "overlay",
    }
    if (stylevars.background === "" || !stylevars.background) delete stylevars.background;
    return stylevars;
  }

  render() {
    return (
        <div
          className={`mx-0 my-0 ${this.padding_top} ${this.padding_bottom} ${this.props.className} ${this.rounded}`}
          id={this.id}
          style={this.style}
        >
          {this.props.children}
        </div>
    );
  }
}
