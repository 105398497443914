import { isRunTime } from "./isRunTime";
const getQuery = () => {
  if (isRunTime() && window.location.href.indexOf("?") > 0) {
    const queryString =
      isRunTime() && window.location.href.split("?")[1].split("&");
    if (queryString !== undefined) {
      let ret = {};
      queryString.forEach((idx) => {
        const param = idx.split("=");
        const key = param[0];
        let value = decodeURIComponent(param[1]);
        try {
          value = JSON.parse(value);
        } catch {} // die silently
        ret[key] = value;
      });
      return ret;
    } else {
      return {};
    }
  } else {
    return {};
  }
};

const setQuery = ({ key, value }) => {
  if (isRunTime() && key && value) {
    window.history.replaceState({}, "", `/?${key}=${value}`)
  }
}

const resetQuery = () => {
  if (isRunTime()) {
    window.history.replaceState({}, "", "/")
  }
}

export { getQuery, setQuery, resetQuery };
